import { Box, HStack, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { useImmer } from 'use-immer';

import ControlledSearch from '@/components/controlled-search/ControlledSearch';
import DepartmentsDropdown from '@/components/departments-dropdown/DepartmentsDropdown';
import PersonnelTypeDropdown from '@/components/personnel-types-dropdown/PersonnelTypeDropdown';
import PublicURLControls from '@/components/view-access-drawer/PublicURLControls';
import UIConfig from '@/config/ui.config';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  setViewAccessIsDepartmentListClosed,
  setViewAccessPersonnelSearchFilter,
  setViewAccessPersonnelTypeIds,
  setViewAccessSelectedDepartmentIds,
} from '@/store/slices/viewAccess.slice';
import { PersonnelType } from '@/types/personnel.types';

interface ViewAccessControlsProps {
  personnelTypes: PersonnelType[];
}

interface UIState {
  isDepartmentListOpen: boolean;
  searchValue: string;
  selectedDropdownDepartmentIds: number[];
}

const ViewAccessControls = (props: ViewAccessControlsProps) => {
  const { personnelTypes } = props;

  const dispatch = useAppDispatch();

  const [uiState, updateUIState] = useImmer<UIState>({
    isDepartmentListOpen: false,
    searchValue: '',
    selectedDropdownDepartmentIds: [],
  });

  const { departments } = useAppSelector((state) => state.departmentsAndTemplates);
  const { selectedPersonnelTypeIds } = useAppSelector((state) => state.viewAccess);

  const personnelTypeOptions = React.useMemo(() => {
    return personnelTypes.map((personnelType) => {
      return {
        label: personnelType.name,
        value: personnelType.ptypeId,
      };
    });
  }, [personnelTypes]);

  // We use an empty dependency array to prevent the recreation of this function
  // on re-render, else we would fire off this callback for every letter in the
  // search string
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = React.useCallback(
    _.debounce((value: string) => {
      dispatch(setViewAccessPersonnelSearchFilter(value));
    }, UIConfig.DEFAULT_DEBOUNCE_TIME_MS),
    [],
  );

  const handleSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateUIState((draft) => {
        draft.searchValue = e.target.value;
      });
      debouncedSearch(e.target.value);
    },
    [debouncedSearch, updateUIState],
  );

  const handlePersonnelTypesChange = React.useCallback(
    (selectedPersonnelTypeIds: number[]) => {
      dispatch(setViewAccessPersonnelTypeIds(selectedPersonnelTypeIds));
    },
    [dispatch],
  );

  const handleDepartmentsChange = React.useCallback(
    (selectedIds: number[]) => {
      updateUIState((draft) => {
        draft.selectedDropdownDepartmentIds = selectedIds;
      });
    },
    [updateUIState],
  );

  const handleDepartmentsListClose = React.useCallback(
    (selectedIds: number[]) => {
      dispatch(setViewAccessSelectedDepartmentIds(selectedIds));
      dispatch(setViewAccessIsDepartmentListClosed(true));
    },
    [dispatch],
  );

  return (
    <Box alignItems={'left'}>
      <VStack>
        <HStack gap={'50px'}>
          <DepartmentsDropdown
            departmentList={departments}
            departmentChangeHandler={handleDepartmentsChange}
            departmentListCloseHandler={handleDepartmentsListClose}
            selectedIds={uiState.selectedDropdownDepartmentIds}
          />
          <PersonnelTypeDropdown
            availablePersonnelTypes={personnelTypeOptions}
            onChangeHandler={handlePersonnelTypesChange}
            selectedPersonnelTypeIds={selectedPersonnelTypeIds}
          />
        </HStack>
        <HStack gap={'60px'} align={'left'}>
          <PublicURLControls />
          <Box h={'64px'} alignItems={'center'} paddingTop={'24px'}>
            <ControlledSearch onChange={handleSearch} placeholder={'Search Personnel'} value={uiState.searchValue} />
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};

export default ViewAccessControls;
