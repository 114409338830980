/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Spinner,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import { useLazyGetPersonnelTypesQuery } from '@/API/personnel.api';
import { useUpdateViewMutation } from '@/API/views.api';
import ViewAccessControls from '@/components/view-access-drawer/ViewAccessControls';
import ViewAccessPersonnel from '@/components/view-access-drawer/ViewAccessPersonnel';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_TOAST_DURATION,
  DEFAULT_UPDATE_SUCCESS_MESSAGE,
  ToastTypes,
} from '@/constants/defaults';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  resetViewAccess,
  setViewAccessPersonnelTypes,
  setViewAccessPublicViewURL,
} from '@/store/slices/viewAccess.slice';
import { setViewPageViewAccessDrawerOpen } from '@/store/slices/viewPage.slice';
import { ApiError } from '@/types/api.types';
import { arraysContainSameValues } from '@/utils/arrays';
import Comparisons from '@/utils/comparisons';
import { getLBPublicViewUrl } from '@/utils/url';

interface ViewAccessDrawerProps {
  onClose: () => void;
}

const ViewAccessDrawer = (props: ViewAccessDrawerProps): React.JSX.Element => {
  const { onClose } = props;

  const { selectedViewData: view, isViewAccessDrawerOpen, publicViewsData } = useAppSelector((state) => state.viewPage);

  const { personnelTypes, selectedPersonnelIDs } = useAppSelector((state) => state.viewAccess);

  const toast = useToast();
  const dispatch = useAppDispatch();

  const {
    isOpen: isConfirmDiscardChangesOpen,
    onOpen: openConfirmDiscardChanges,
    onClose: closeConfirmDiscardChanges,
  } = useDisclosure();

  const cancelDiscardChangesRef = React.useRef(null);

  const [getPersonnelTypes] = useLazyGetPersonnelTypesQuery();

  React.useEffect(() => {
    (async () => {
      try {
        const response = await getPersonnelTypes().unwrap();

        if (response) {
          dispatch(setViewAccessPersonnelTypes(response));
        }
      } catch (err) {
        toast({
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          position: 'top',
          status: ToastTypes.ERROR,
          title: 'Could not fetch personnel types',
        });
      }
    })();
    // Initial fetch only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const publicView = publicViewsData.find((publicView) => publicView.viewId === view?.viewId);

    if (publicView) {
      dispatch(setViewAccessPublicViewURL(getLBPublicViewUrl(publicView.guid)));
    }
  }, [dispatch, publicViewsData, view, view?.viewId]);

  const [updateView, updateViewResponse] = useUpdateViewMutation();

  const hasPersonnelAccessChanged = React.useCallback(() => {
    if (!view?.accessibleBy) return false;

    return !arraysContainSameValues(view?.accessibleBy, selectedPersonnelIDs, [
      (item) => Comparisons.isLBSAccount(item as number),
    ]);
  }, [view, selectedPersonnelIDs]);

  const handleClose = React.useCallback(
    (bypassConfirmation = false) => {
      if (!bypassConfirmation && hasPersonnelAccessChanged()) {
        openConfirmDiscardChanges();
        return;
      }

      if (onClose) {
        onClose();
      }

      dispatch(setViewPageViewAccessDrawerOpen(false));
      dispatch(resetViewAccess(undefined));
    },
    [dispatch, hasPersonnelAccessChanged, onClose, openConfirmDiscardChanges],
  );

  const confirmDiscardChangesAndClose = () => {
    closeConfirmDiscardChanges();
    handleClose(true);
  };

  const getConfirmDiscardChangesDialog = () => {
    return (
      <AlertDialog
        isOpen={isConfirmDiscardChangesOpen}
        leastDestructiveRef={cancelDiscardChangesRef}
        onClose={closeConfirmDiscardChanges}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {`${view?.name} Access`}
            </AlertDialogHeader>

            <AlertDialogBody>Discard changes and close?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelDiscardChangesRef} onClick={closeConfirmDiscardChanges}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDiscardChangesAndClose} ml={3}>
                Discard
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  };

  const handleSubmit = React.useCallback(async () => {
    if (!view) return;

    const { viewId, name, filterId, themeId } = view;

    try {
      if (hasPersonnelAccessChanged()) {
        await updateView({
          accessibleBy: selectedPersonnelIDs,
          filterId,
          name,
          themeId,
          viewId,
        }).unwrap();
      }

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.SUCCESS,
        title: DEFAULT_UPDATE_SUCCESS_MESSAGE,
      });
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? DEFAULT_ERROR_MESSAGE;

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    } finally {
      handleClose();
    }
  }, [view, hasPersonnelAccessChanged, toast, updateView, selectedPersonnelIDs, handleClose]);

  return (
    <Drawer
      isOpen={isViewAccessDrawerOpen}
      onClose={handleClose}
      placement={'right'}
      size={'lg'}
      returnFocusOnClose={false}
    >
      <DrawerOverlay />
      <DrawerContent w={'1300px'} maxW={'1300px'}>
        {getConfirmDiscardChangesDialog()}
        <DrawerCloseButton />
        <DrawerHeader borderBottomWidth={'1px'}>{`${view?.name} Access`}</DrawerHeader>
        <DrawerBody>
          {
            <VStack gap={'50px'}>
              <ViewAccessControls personnelTypes={personnelTypes} />
              <ViewAccessPersonnel />
            </VStack>
          }
        </DrawerBody>
        <DrawerFooter>
          <HStack>
            <Button
              colorScheme={'blue'}
              onClick={handleSubmit}
              isLoading={updateViewResponse.isLoading}
              spinner={<Spinner />}
            >
              Submit
            </Button>
            <Button colorScheme={'blue'} variant={'outline'} onClick={() => handleClose()}>
              Cancel
            </Button>
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ViewAccessDrawer;
